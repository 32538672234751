import { setupUiFlippers, setupTestMode } from './utils/e2e-test';

const FLIPPERS_IN_TESTS = [
  'ui_datagrid_request_caching',
  'ui_integration_remove_alert',
  'ui_dialog_headline_sanitize',
  'ui_datagrid_two_lines',
  'ui_datagrid_card_layout',
  'ui_datagrid_groups',
  'ui_select_option_allow_falsy'
];

setupUiFlippers(FLIPPERS_IN_TESTS);
setupTestMode();
